import React from "react";
import { NavLink } from "react-router-dom";
import MyOffcanvas from "./offcanvas.jsx";

const NavBar = ({ user }) => {
  
  return (
    <nav className="navbar navbar-expand bg-light" style={{ zIndex: "200" }}>
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <span className="font-monospace fw-normal fs-5"> ThinkTank-AI </span>
        </NavLink>
        <div className="navbar">
          {user && (
            <NavLink
              className="nav-item nav-link"
              aria-current="page"
              to="/myideas"
            >
              <button
                type="button"
                className="btn btn-light shadow-none"
                style={{ borderStyle: "none" }}
              >
                My Ideas
              </button>
            </NavLink>
          )}

          {!user && (
            <NavLink
              className="nav-item nav-link"
              aria-current="page"
              to="/signin"
            >
              <button
                type="button"
                className="btn btn-light shadow-none"
                style={{ borderStyle: "none" }}
              >
                Sign In
              </button>
            </NavLink>
          )}
          {!user && (
            <NavLink
              className="nav-item nav-link"
              aria-current="page"
              to="/register"
            >
              <button
                type="button"
                className="btn btn-light shadow-none"
                style={{ borderStyle: "none" }}
              >
                Sign Up
              </button>
            </NavLink>
          )}
          <MyOffcanvas user={user} />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
