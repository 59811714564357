import React from "react";
import { useParams } from "react-router-dom";
import ResetPassword from './resetPassword';

const GetResetPasswordParam = () => {
  const { uid, reset_token } = useParams();

  return (
    <div>
      <ResetPassword uid={uid} reset_token={reset_token} />
    </div>
  );
};

export default GetResetPasswordParam;



