import http from "./httpService";

export function create_idea(data, token) {
  return http.post("/create-idea", data, { headers: {'Authorization': `Token ${token}`}});
}

export function save_idea(data, token) {
  return http.post("/saveidea", data, { headers: {'Authorization': `Token ${token}`}});
}

export function get_ideas(token) {
  return http.get("/myideas", { headers: {'Authorization': `Token ${token}`}});
}

export function unsave_idea(data, token) {
  return http.post("/unsaveidea", data, { headers: {'Authorization': `Token ${token}`}});
}
