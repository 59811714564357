import React, { Component } from "react";
import { activate } from "../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class Activate extends Component {
  state = { not_found: true };

  async componentDidMount() {
    document.title = "ThinkTank-AI";
    const { uid, ac_token } = this.props;
    if (await this.handleActivate(uid, ac_token)) {
      this.setState({ not_found: false });
    }
  }

  handleActivate = async (uid, ac_token) => {
    const data = { uid: uid, token: ac_token };
    const response = await activate(data);
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { not_found } = { ...this.state };
    return (
      <div className="container">
        {not_found && (
          <p className="text-center fs-1 fw-bold font-monospace pt-3">
            Not Found
          </p>
        )}
        {!not_found && (
          <div className="col text-center">
            <FontAwesomeIcon
              icon={faBrain}
              size="6x"
              style={{ marginTop: "3.1rem", animationDuration: "2s" }}
            />
            <p className="text-center fs-1 fw-bold font-monospace pt-3">
              Your email is confirmed! You can now sign in to start generating
              ideas.
            </p>
            <Link
              className="nav-link text-center"
              aria-current="page"
              to="/signin"
              style={{
                marginTop: "2.5rem",
              }}
            >
              <button
                type="button"
                className="btn btn-outline-dark btn-lg shadow-none"
                style={{ textAlign: "center" }}
              >
                Sign In
              </button>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

export default Activate;
