import React, { Component } from "react";
import { getTTToken } from "../services/authService";

class TTToken extends Component {
  state = { tt_tokens: false };


  async componentDidMount() {
    const token = localStorage.getItem("token");
    const response = await getTTToken(token);
    const tt_tokens = response.data.total_tt_token;
    this.setState({ tt_tokens });
  }
  render() {
    const { tt_tokens } = this.state;
    return (
      <div>
        {tt_tokens !== false && (
          <p className="fs-3 font-monospace text-center">You have <b>{tt_tokens}</b> tokens</p>
        )}
        {tt_tokens === false && (
          <p className="fs-3 font-monospace text-center"></p>
        )}
      </div>
    );
  }
}

export default TTToken;
