import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

function Landing() {
  const [bgColor, setBgColor] = useState("white");
  const [txtColor, setTxtColor] = useState("#212529");

  const toggleTheme = () => {
    if (bgColor === "white") {
      setBgColor("#212529");
    } else {
      setBgColor("white");
    }
    if (txtColor === "#212529") {
      setTxtColor("white");
    } else {
      setTxtColor("#212529");
    }
  };
  useEffect(() => {
    // document.body.className = theme;
    document.body.style.backgroundColor = `${bgColor}`;
  }, [bgColor]);

  return (
    <div className="container mb-4">
      <div className="col text-center">
        <FontAwesomeIcon
          icon={faBrain}
          size="6x"
          style={{
            marginTop: "3.1rem",
            color: `${txtColor}`,
          }}
        />
      </div>
      <div className="card mt-5 mb-4" style={{ backgroundColor: `${bgColor}` }}>
        <div className="card-body mb-4">
          <p
            className="text-center fs-1 fw-bold font-monospace"
            style={{ marginTop: "1.5rem", color: `${txtColor}` }}
          >
            Generate ideas using AI
          </p>
          <p
            className="text-center fs-3 fw-bold font-monospace"
        
            style={{
              marginTop: "3rem",
            
              color: `${txtColor}`,
            }}
          >
            To get the next best idea
          </p>
          <p
            className="text-center fs-3 fw-bold font-monospace"
            
            style={{
              marginTop: "2.5rem",
              color: `${txtColor}`,
            
            }}
          >
            Choose serious or funny
          </p>
          <p
            className="text-center fs-3 fw-bold font-monospace"

            style={{
              marginTop: "2.5rem",
              color: `${txtColor}`,
             
            }}
          >
            Provide one or two keywords
          </p>
          <p
            className="text-center fs-3 fw-bold font-monospace"
           
            style={{
              marginTop: "2.5rem",
              color: `${txtColor}`,
              
            }}
          >
            Let the{" "}
            <FontAwesomeIcon
              className="brain"
              icon={faBrain}
              color={txtColor}
              style={{ animationDuration: "2s" }}
              beatFade
            />
            {" "}do the rest
          </p>
          <p
            className="text-center fs-1 fw-bold font-monospace"
            style={{
              marginTop: "3rem",
              color: `${txtColor}`,
             
            }}
          >
            Please sign in to continue
          </p>
          <div className="row mt-5 justify-content-center">
            <div className="col-4">
              <Link
                className="nav-link text-end"
                aria-current="page"
                to="/signin"
              >
                <button
                  type="button"
                  className={`btn btn-lg shadow-none ${
                    txtColor === "#212529"
                      ? "btn-outline-dark"
                      : "btn-outline-light"
                  }`}
                  style={{
                    textAlign: "center",
                    paddingLeft: "1.25rem",
                    paddingRight: "1.25rem",
                    
                    marginRight: "1.2rem",
                  }}
                >
                  Sign In
                </button>
              </Link>
            </div>
            <div className="col-4">
              <Link
                className="nav-link text-left"
                aria-current="page"
                to="/register"
                style={{
                  marginLeft: "1.2rem",
             
                }}
              >
                <button
                  type="button"
                  className={`btn btn-lg shadow-none ${
                    txtColor === "#212529"
                      ? "btn-outline-dark"
                      : "btn-outline-light"
                  }`}
                  style={{ textAlign: "center" }}
                >
                  Sign Up
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
