import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { resetPassword } from "../services/authService";

class ResetPassword extends Component {
  state = {
    not_found: true,
    data: { password: "", confirm_password: "" },
    message: "",
    canSignIn: false,
  };

  async componentDidMount() {
    document.title = "ThinkTank-AI";
    const { uid, reset_token } = this.props;

    if (uid && reset_token) {
      this.setState({ not_found: false });
    }
  }

  handleChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  doSubmit = async (e) => {
    //Call server
    e.preventDefault();
    const { password, confirm_password } = { ...this.state.data };
    if (password !== confirm_password) {
      this.setState({ message: "Passwords entered do not match" });
    } else {
      try {
        const { uid, reset_token } = this.props;
        const data = { uid: uid, token: reset_token, new_password: password };
        const response = await resetPassword(data);
        this.setState({ password: "", confirm_password: "" });
        this.setState({
          message:
            "Your password is changed successfully. You can now sign in with your new password",
        });
        this.setState({ canSignIn: true });
      } catch (error) {
        if (error.response.status === 400) {
          this.setState({ errors: true });
          this.setState({
            message:
              "This password can be easily guessed. Please use a stronger password.",
          });
        }
      }
    }
  };

  render() {
    const { not_found, data, message, canSignIn } = {
      ...this.state,
    };
    return (
      <div className="container">
        {not_found && (
          <p className="text-center fs-1 fw-bold font-monospace pt-3">
            ...
          </p>
        )}
        {!not_found && (
          <div className="col text-center">
            <FontAwesomeIcon
              icon={faBrain}
              size="6x"
              style={{ marginTop: "3.1rem", animationDuration: "2s" }}
            />
            <form name="wordForm" onSubmit={this.doSubmit}>
              <div className="flex justify-center text-center mt-4">
                <div className="col text-center justify-center">
                  <input
                    type="password"
                    maxLength="30"
                    className="form-control-lg text-center"
                    id="inputPassword"
                    name="password"
                    placeholder="Password"
                    value={data["password"]}
                    onChange={this.handleChange}
                  />
                </div>
              </div>
              <div className="flex justify-center text-center mt-2">
                <input
                  type="password"
                  maxLength="30"
                  className="form-control-lg text-center"
                  id="inputConfirmPassword"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={data["confirm_password"]}
                  onChange={this.handleChange}
                />
              </div>
              <div className="row justify-center mt-3">
                <div className="col text-center">
                  <button
                    type="submit"
                    className="btn btn-outline-dark shadow-none"
                  >
                    Reset Password
                  </button>
                </div>
              </div>
              <div className="row justify-center mt-3">
                <div className="col text-center">
                  <p className=" text-center fs-5 font-monospace mt-3">
                    {message}
                  </p>
                </div>
              </div>
            </form>
            {canSignIn && (
              <Link
                className="nav-link text-center"
                aria-current="page"
                to="/signin"
                style={{
                  marginTop: "2.5rem",
                }}
              >
                <button
                  type="button"
                  className="btn btn-outline-dark btn-lg shadow-none"
                  style={{ textAlign: "center" }}
                >
                  Sign In
                </button>
              </Link>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default ResetPassword;
