import React, { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import TTToken from "./ttToken";
import Logout from "./logout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import ProductDisplay from "./productDisplay";

function MyOffcanvas({ user }) {
  const [show, setShow] = useState(false);
  const [infoClicked, setInfoClicked] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        type="button"
        className="btn btn-lg btn-light shadow-none"
        onClick={handleShow}
      >
        ?
      </button>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <FontAwesomeIcon icon={faBrain} size="2x" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        {!user && (
          <Offcanvas.Body>
            <p className="fs-4 font-monospace mb-4">Welcome to ThinTank-AI</p>
            <p className="fs-6 font-monospace">
              With just a few clicks, you can come up with the next best
              business idea!
              <br></br> <br></br> Simply choose how many keywords you want to
              use.<br></br> <br></br> Enter them in the given fields and choose
              between <b>serious</b> or <b>funny</b>: <br></br> <br></br>
              <b>Serious:</b> Practical and realistic ideas. <br></br> <br></br>
              <b>Funny:</b> More creative and out-of-the-box ideas (and
              occasionally, downright hilarious). <br></br> <br></br> Save your
              favorite ideas. <br></br> <br></br> Sign in to start
              brainstorming!
            </p>
            <div className="row mt-5 justify-content-center">
              <div className="col-4">
                <Link
                  className="nav-link text-center"
                  aria-current="page"
                  to="/signin"
                >
                  <button
                    type="button"
                    className="btn shadow-none btn-outline-dark"
                    style={{
                      paddingLeft: "1rem",
                      paddingRight: "1rem",
                      textAlign: "center",
                    }}
                  >
                    Sign In
                  </button>
                </Link>
              </div>
              <div className="col-4">
                <Link
                  className="nav-link text-center"
                  aria-current="page"
                  to="/register"
                >
                  <button
                    type="button"
                    className="btn shadow-none btn-outline-dark"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Sign Up
                  </button>
                </Link>
              </div>
            </div>
            <div className="row mt-5 justify-content-center">
              <div className="col-5">
                <Link
                  className="nav-link text-center"
                  aria-current="page"
                  to="/privacypolicy"
                >
                  <button
                    type="button"
                    className="btn btn-light btn-sm shadow-none"
                    style={{ borderStyle: "none", background: "transparent" }}
                  >
                    Privacy Policy
                  </button>
                </Link>
              </div>
            </div>
            <div className="row mt-2 justify-content-center">
              <div className="col-5">
                <Link
                  className="nav-link text-center"
                  aria-current="page"
                  to="/terms"
                >
                  <button
                    type="button"
                    className="btn btn-light btn-sm shadow-none"
                    style={{ borderStyle: "none", background: "transparent" }}
                  >
                    Terms of Service
                  </button>
                </Link>
              </div>
            </div>
          </Offcanvas.Body>
        )}
        {user && (
          <Offcanvas.Body>
            {<TTToken user={user} />}
            <p className="fs-4 font-monospace text-center">
              Get More Tokens:
            </p>{" "}
            <p className="fs-6 font-monospace text-center">
              Each bundle has 30 tokens
            </p>
            <ProductDisplay />
            <div className="flex row justify-center mt-4 mb-3">
              <div className="col">
                <button
                  type="button"
                  className="btn rounded-pill btn-outline-secondary btn shadow-none"
                  onClick={() =>
                    infoClicked === false
                      ? setInfoClicked(true)
                      : setInfoClicked(false)
                  }
                >
                  {infoClicked === false ? "More Info" : "Close"}
                </button>
              </div>
            </div>
            {infoClicked && (
              <div>
                <p className="fs-6 font-monospace">
                  Hi {user.username}, we're excited to have you using
                  ThinkTank-AI. <br></br>
                  <br></br> Each time you generate an idea, you'll use one
                  token.
                  <br></br>
                  <br></br>
                  Don't worry, you'll get 10 free tokens every month to get you
                  started.<br></br>
                  <br></br> If you run out and want to generate more ideas, you
                  can purchase additional tokens at any time. <br></br>
                  <br></br>
                  We hope you enjoy using ThinkTank-AI and that it helps spark
                  some great business ideas for you. Happy brainstorming!{" "}
                  <br></br>
                  <br></br>
                  If you have any feedback or feature requests feel free to
                  reach us at: <br></br>
                  feedback@thinktank-ai.com
                </p>
                <div className="text-center mt-4">{<Logout />}</div>

                <div className="row mt-4 justify-content-center">
                  <div className="col-5">
                    <Link
                      className="nav-link text-center"
                      aria-current="page"
                      to="/privacypolicy"
                    >
                      <button
                        type="button"
                        className="btn btn-light btn-sm shadow-none"
                        style={{
                          borderStyle: "none",
                          background: "transparent",
                        }}
                      >
                        Privacy Policy
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="row mt-2 justify-content-center">
                  <div className="col-5">
                    <Link
                      className="nav-link text-center"
                      aria-current="page"
                      to="/terms"
                    >
                      <button
                        type="button"
                        className="btn btn-light btn-sm shadow-none"
                        style={{
                          borderStyle: "none",
                          background: "transparent",
                        }}
                      >
                        Terms of Service
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </Offcanvas.Body>
        )}
      </Offcanvas>
    </>
  );
}

export default MyOffcanvas;
