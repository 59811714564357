import React, { Component } from "react";
import { save_idea } from "../services/generateService";
import { Link } from "react-router-dom";

class SaveIdea extends Component {
  state = { clickSuccess: false };

  handleClick = async (ideaId) => {
    const data = { id: ideaId };
    const token = localStorage.getItem("token");
    const response = await save_idea(data, token);
    if (response.status === 204) {
      this.setState({ clickSuccess: true });
    }
  };
  render() {
    const { ideaId } = this.props;
    const { clickSuccess } = { ...this.state };

    return (
      <div>
        {!clickSuccess && (
          <button
            type="button"
            className="btn rounded-pill btn-outline-dark btn-sm shadow-none"
            onClick={() => this.handleClick(ideaId)}
          >
            Save Idea
          </button>
        )}
        {clickSuccess && (<Link
              className="nav-link"
              aria-current="page"
              to="/myideas"
            >
              <button
                type="button"
                className="btn rounded-pill btn-outline-dark btn-sm shadow-none"
              >
                My Ideas
              </button>
            </Link>
          )}
      </div>
    );
  }
}
export default SaveIdea;
