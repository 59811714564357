import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

function LandingLoading() {

    return (
      <div>
        <div className="col text-center">
          <FontAwesomeIcon
            icon={faBrain}
            size="6x"
            style={{ marginTop: "3.1rem", animationDuration: "2s" }}
          />
        </div>

        <p
          className="text-center fs-1 fw-bold font-monospace"
          style={{ marginTop: "3rem" }}
        >
          Loading...
        </p>
      </div>
    );
}
export default LandingLoading;
