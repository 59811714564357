import React, { Component } from "react";
import Joi from "joi";
class IdeaForm extends Component {
  state = {
    data: { word1: "", word2: "", word3: "" },
    errors: { error1: "", error2: "", error3: "" },
  };

  validateInput(name) {
    const { data, errors } = { ...this.state };
    const schema = Joi.string().trim().min(2).max(20).required();
    const { error } = schema.validate(data[name]);
    if (!error) {
      errors[name] = "";
      this.setState({ errors });
      return null;
    } else {
      let errMessage = error.message;
      let errorLabel = name.charAt(0).toUpperCase() + name.slice(1);
      errorLabel = errorLabel.replace("Word", "Word ");
      let result = errMessage.replace(`"value"`, errorLabel);
      errors[name] = result;
      this.setState({ errors });
      return result;
    }
  }
  handleChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.doSubmit();
  };
  renderInput(name) {
    const { data, errors } = { ...this.state };
    let formLabel = name.charAt(0).toUpperCase() + name.slice(1);
    formLabel = formLabel.replace("Word", "Word ");
    return (
      <div className="text-center">
        <label htmlFor={name} className="form-label-sm text-center">
          Enter {formLabel}
        </label>
        <input
          type="text"
          maxLength="20"
          className="form-control text-center"
          name={name}
          id={name}
          value={data[name]}
          onChange={this.handleChange}
          style={
            errors[name] ? { border: "2px solid red", boxShadow: "none" } : {boxShadow: "none"}
          }
        />
      </div>
    );
  }
}

export default IdeaForm;
