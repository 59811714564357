import http from "./httpService";

export function registerUser(data) {
  return http.post("/auth/register", data);
}

export function loginUser(data) {
  return http.post("/auth/login", data);
}

export function googleSignIn(data) {
  return http.post("/auth/google_signin", data);
}

export function getLoggedUser(token) {
  return http.get("/auth/get_user", { headers: {'Authorization': `Token ${token}`}});
}

export function logout(token) {
    return http.post("/auth/logout",{},{ headers: {'Authorization': `Token ${token}`}});
  }

export function activate(data) {
    return http.post("/auth/activate", data);
  }

  export function getTTToken(token) {
    return http.get("/auth/get_tt_token_num", { headers: {'Authorization': `Token ${token}`}});
  }


  export function createCheckoutSession(data, token) {
    return http.post("/create-checkout-session", data, { headers: {'Authorization': `Token ${token}`}});
  }

  export function sendResetEmail(data) {
    return http.post("/auth/forgot_password", data);
  }

  export function resetPassword(data) {
    return http.post("/auth/reset_password", data);
  }
