// import './App.css';
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "./components/notFound";
import Generator from "./components/generator";
import NavBar from "./components/navbar";
import RegisterForm from "./components/register";
import { getLoggedUser } from "./services/authService";
import GetActivateParam from "./components/getActivateParam";
import LoginForm from "./components/login";
import MyIdeas from './components/myIdeas';
import Checkout from './components/checkout';
import MainPage from "./components/mainPage";
import ForgotPassword from "./components/forgotPassword";
import GetResetPasswordParam from "./components/getResetPasswordParam";
import PrivacyPolicy from "./components/privacyPolicy";
import Terms from './components/terms';

class App extends Component {
  state = {};

  async componentDidMount() {
    const isLoaded = true
    try {
      
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const { data } = await getLoggedUser(token);
          const user = data;
          
          this.setState({ user });
        } catch (error) {
        }
      }
    } catch (ex) {
    }
    this.setState({ isLoaded });
  }

  render() {
    const { user, isLoaded } = this.state;
    return (
      <React.Fragment>
        <NavBar user={user} />
        <main className="container">
          <Routes>
            <Route exact path="/" element={<MainPage user={user} isLoaded={isLoaded} />} />
            <Route exact path="/generator" element={<Generator user={user} isLoaded={isLoaded} />} />
            <Route path="/register" element={<RegisterForm />} />
            <Route path="/signin" element={<LoginForm />} />
            <Route path="/myideas" element={<MyIdeas />} />
            <Route
              path="/activate/:uid/:ac_token"
              element={<GetActivateParam />}
            />
            <Route
              path="/reset-password/:uid/:reset_token"
              element={<GetResetPasswordParam />}
            />
            <Route
              path="/checkout"
              element={<Checkout />}
            />
            <Route
              path="/forgotpassword"
              element={<ForgotPassword />}
            />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
      </React.Fragment>
    );
  }
}
export default App;
