import React, { Component } from "react";
import { get_ideas, unsave_idea } from "../services/generateService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

class MyIdeas extends Component {
  state = { ideas: [] };

  async componentDidMount() {
    const token = localStorage.getItem("token");
    const response = await get_ideas(token);
    const ideas = response.data;
    this.setState({ ideas });
  }

  deleteIdea = async (ideaId) => {
    const token = localStorage.getItem("token");
    const data = { id: ideaId };
    const response = await unsave_idea(data, token);
    if (response.status === 204) {
      const idea_list = await get_ideas(token);
      const ideas = idea_list.data;
      this.setState({ ideas });
    }
  };
  render() {
    const { ideas } = { ...this.state };
    if (ideas.length !== 0) {
      return (
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col text-center">
              <FontAwesomeIcon
                icon={faBrain}
                size="6x"
                style={{ marginTop: "3.1rem", animationDuration: "2s" }}
              />
            </div>
          </div>
          {ideas.map((idea) => (
            <div key={idea.id}>
              <div className="row mt-4 mb-4 justify-content-end align-items-center">
                <div className="col-md-8 col-sm-12 text-start">
                  <div className="card">
                    <h5 class="card-title text-start mx-3 mt-3">
                      Keywords:{" "}
                      {idea.wordCount === 1
                        ? idea.word1
                        : idea.word1 + " & " + idea.word2}
                    </h5>

                    <div className="card-body text-start mx-4 mb-1 fs-6 font-monospace">
                      {idea.output}
                    </div>
                  </div>
                </div>
                <div className="col-2 ">
                  <button
                    type="button"
                    className="btn-close shadow-none"
                    onClick={() => this.deleteIdea(idea.id)}
                  ></button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col text-center">
              <FontAwesomeIcon
                icon={faBrain}
                size="6x"
                style={{ marginTop: "3.1rem", animationDuration: "2s" }}
              />
            </div>
          </div>

          <div className="row mt-5 justify-content-center">
            <div className="col">
              <p className=" text-center fs-3 fw-bold font-monospace">You will see your saved ideas here. <br></br> <br></br> Head back to the main page to start generating ideas!</p>
            </div>
          </div>
        </div>
      );
    }
  }
}
export default MyIdeas;
