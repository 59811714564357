import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { sendResetEmail } from '../services/authService';

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(0);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
        const new_data = {email: email}
        const { data } = await sendResetEmail(new_data);
        setEmailSent(1);
      } catch (error) {
        if (error.response.status === 404) {
          // show email entered does not show in our records
        setEmailSent(2);
        }
      }
    };

  return (
    <div className="container">
      <div className="col text-center">
        <FontAwesomeIcon
          icon={faBrain}
          size="6x"
          style={{ marginTop: "3.1rem", animationDuration: "2s" }}
        />
      </div>

      <div className="row mt-5 justify-content-center align-items-center">
        <div className="col d-flex justify-content-center">
          <div className="card" style={{ width: "30rem" }}>
            <div className="card-body">
              <h3 className="card-title text-center fs-1 font-monospace">
                ThinkTank-AI
              </h3>
              <div className="flex row justify-center mt-4">
                <div className="col text-center">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control-lg text-center"
                        id="inputEmail"
                        name="email"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                    <div className="row justify-center mt-3 mb-3">
                      <div className="col text-center">
                        <button
                          type="submit"
                          className="btn btn-outline-dark shadow-none"
                        >
                          Send Reset Link
                        </button>
                      </div>
                    </div>
                  </form>
                  {emailSent === 1 && <h4>An email with a reset link has been sent to the provided address.</h4>}
                  {emailSent === 2 && <h4>The provided email does not match our records.</h4>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
