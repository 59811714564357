import React from "react";
import { useParams } from "react-router-dom";
import Activate from "./activate";

const GetActivateParam = () => {
  const { uid, ac_token } = useParams();

  return (
    <div>
      <Activate uid={uid} ac_token={ac_token} />
    </div>
  );
};

export default GetActivateParam;
