import React, { Component } from "react";
import Joi from "joi";
import { registerUser } from "../services/authService";
import { googleSignIn } from "../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
class RegisterForm extends Component {
  state = {
    data: {
      email: "",
      username: "",
      password: "",
    },
    errors: 0,
    errMessage: "",
    tt_signin: false,
    create_account_pressed: false,
  };

  async componentDidMount() {
    document.title = "Sign Up - ThinkTank-AI";
    /*global google*/
    if (google) {
      google.accounts.id.initialize({
        client_id:
          "238741266800-a740e6q674sqlhujfvjq8c925fe4jrf7.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        {
          shape: "pill",
          theme: "filled_black",
          size: "large",
          text: "signup_with",
        }
      );
      google.accounts.id.prompt();
    }
  }

  async handleCredentialResponse(response) {
    const { data } = await googleSignIn(response);
    localStorage.setItem("token", data["token"]);
    window.location = "/generator";
  }

  validateInput() {
    const { data } = { ...this.state };
    const schema = Joi.object({
      username: Joi.string().alphanum().min(3).max(30).required(),
      password: Joi.string().min(8).max(30).required(),
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    });
    const { error } = schema.validate({
      username: data["username"],
      email: data["email"],
      password: data["password"],
    });
    if (!error) {
      this.setState({ errors: 0 });
      return 0;
    } else {
      this.setState({ errors: 1 });
      return 1;
    }
  }

  handleChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    const new_data = { ...this.state.data };

    const errors = this.validateInput();
    this.setState({ errMessage: "" });

    if (!errors) {
      try {
        this.setState({ create_account_pressed: true });
        const response = await registerUser(new_data);
      } catch (error) {
        if (error.response.status === 400) {
          this.setState({ errors: 2 });
          if (error.response.data.password) {
            this.setState({
              errMessage:
                "This password can be easily guessed. Please use a stronger password.",
            });
            this.setState({ create_account_pressed: false });
          }
          if (error.response.data.username) {
            this.setState({ errMessage: "This username is already in use." });
            this.setState({ create_account_pressed: false });
          }
          if (error.response.data.email) {
            this.setState({
              errMessage: "An account with this email already exists.",
            });
            this.setState({ create_account_pressed: false });
          }
        }
      }
    }
  };
  render() {
    const { data } = { ...this.state };

    return (
      <div className="container">
        <div className="col text-center">
          <FontAwesomeIcon
            icon={faBrain}
            size="6x"
            style={{ marginTop: "3.1rem", animationDuration: "2s" }}
          />
        </div>
        <div className="row mt-5 justify-content-center align-items-center">
          <div className="col d-flex justify-content-center">
            <div className="card" style={{ width: "30rem" }}>
              <div className="card-body">
                <h3 className="card-title text-center fs-1 font-monospace">
                  ThinkTank-AI
                </h3>
                <div className="flex row justify-center mt-4">
                  <div className="col text-center">
                    <button
                      id="googleButton"
                      style={{
                        borderRadius: "0rem",
                        borderColor: "white",
                        height: "2.5rem",
                        width: "13.05rem",
                        borderWidth: "0rem",
                        background: "white",
                      }}
                    ></button>
                  </div>
                </div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center mt-4 border-bottom"></div>
                <div className="flex row justify-center mt-4 pt-1 pb-2">
                  <div className="col text-center">
                    <button
                      type="button"
                      className="btn btn-dark shadow-none"
                      style={{
                        borderColor: "#222222",
                        height: "2.5rem",
                        width: "12.3rem",
                        borderRadius: "3rem",
                        fontSize: "14px",
                        background: "#222222",
                      }}
                      onClick={() =>
                        this.state.tt_signin === false
                          ? this.setState({ tt_signin: true })
                          : this.setState({ tt_signin: false })
                      }
                    >
                      {this.state.tt_signin === false
                        ? "Sign up with email"
                        : "Close"}
                    </button>
                  </div>
                </div>
                {this.state.tt_signin && (
                  <form name="wordForm" onSubmit={this.doSubmit}>
                    <div className="flex justify-center text-center mt-3">
                      <input
                        type="email"
                        className="form-control-lg text-center"
                        id="inputEmail"
                        name="email"
                        aria-describedby="emailHelp"
                        placeholder="Email"
                        onChange={this.handleChange}
                        value={data["email"]}
                      />
                    </div>
                    <div className="flex justify-center text-center mt-2">
                      <input
                        type="text"
                        maxLength="20"
                        className="form-control-lg text-center"
                        id="inputUsername"
                        name="username"
                        placeholder="Username"
                        onChange={this.handleChange}
                        value={data["username"]}
                      />
                    </div>
                    <div className="flex justify-center text-center mt-2">
                      <input
                        type="password"
                        maxLength="30"
                        className="form-control-lg text-center"
                        id="inputPassword"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleChange}
                        value={data["password"]}
                      />
                    </div>
                    <div className="row justify-center mt-3">
                      <div className="col text-center">
                        <button
                          type="submit"
                          className="btn btn-outline-dark shadow-none"
                        >
                          Create Account
                        </button>
                      </div>
                    </div>
                  </form>
                )}
                {this.state.errors === 0 &&
                  this.state.errMessage === "" &&
                  this.state.create_account_pressed === true && (
                    <div className="row justify-center mt-3">
                      <div className="col text-center">
                        <h6 className="text-center fs-6 ">
                          A confirmation email has been sent. Please check your
                          email to verify your account.
                        </h6>
                      </div>
                    </div>
                  )}
                {this.state.errors === 1 && (
                  <div className="row justify-center mt-3">
                    <div className="col text-center">
                      <h6 className="text-center fs-6 ">
                        - Email must be a valid email address. <br />
                        - Username must be at least 3 characters long.
                        <br />- Username must only contain alpha-numeric
                        characters. <br />- Password must be at least 8
                        characters long.
                      </h6>
                    </div>
                  </div>
                )}
                {this.state.errMessage !== "" && (
                  <div className="row justify-center mt-3">
                    <div className="col text-center">
                      <h6 className="text-center fs-6 ">
                        {this.state.errMessage}
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default RegisterForm;
