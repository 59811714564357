import React, { Component } from "react";
import Joi from "joi";
import { loginUser, googleSignIn } from "../services/authService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

class LoginForm extends Component {
  state = {
    data: {
      username: "",
      password: "",
    },
    errors: false,
    tt_signin: false,
  };

  componentDidMount() {
    document.title = "Sign In - ThinkTank-AI";

    /*global google*/
    if (google) {
      google.accounts.id.initialize({
        client_id:
          "238741266800-a740e6q674sqlhujfvjq8c925fe4jrf7.apps.googleusercontent.com",
        callback: this.handleCredentialResponse,
      });
      google.accounts.id.renderButton(
        document.getElementById("googleButton"),
        {
          shape: "pill",
          theme: "filled_black",
          size: "large",
          text: "signin_with",
        }
      );
      google.accounts.id.prompt();
    }
  }

  async handleCredentialResponse(response) {
    const { data } = await googleSignIn(response);
    localStorage.setItem("token", data["token"]);
    window.location = "/generator";
  }

  validateInput() {
    const { data, errors } = { ...this.state };
    const schema = Joi.object({
      username: Joi.string().alphanum().min(3).max(30).required(),
      password: Joi.string().min(8).max(30).required(),
    });
    const { error, value } = schema.validate({
      username: data["username"],
      password: data["password"],
    });
    if (!error) {
      this.setState({ errors: false });
      return false;
    } else {
      this.setState({ errors: true });
      return true;
    }
  }

  handleChange = (e) => {
    const data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  doSubmit = async (e) => {
    e.preventDefault();
    const { data, errors } = { ...this.state };
    const new_data = data;
    const error = this.validateInput();
    if (!error) {
      try {
        const { data } = await loginUser(new_data);
        this.setState({ password: "" });
        localStorage.setItem("token", data["token"]);

        window.location = "/generator";
      } catch (error) {
        if (error.response.status === 400) {
          this.setState({ errors: true });
        }
      }
    }
  };

  render() {
    const data = { ...this.state.data };

    return (
      <div className="container">
        <div className="col text-center">
          <FontAwesomeIcon
            icon={faBrain}
            size="6x"
            style={{ marginTop: "3.1rem", animationDuration: "2s" }}
          />
        </div>

        <div className="row mt-5 justify-content-center align-items-center">
          <div className="col d-flex justify-content-center">
            <div className="card" style={{ width: "30rem" }}>
              <div className="card-body">
                <h3 className="card-title text-center fs-1 font-monospace">
                  ThinkTank-AI
                </h3>
                <div className="flex row justify-center mt-4">
                  <div className="col text-center">
                    <button
                      id="googleButton"
                      style={{
                        borderRadius: "0rem",
                        borderColor: "white",
                        height: "2.5rem",
                        width: "13.05rem",
                        borderWidth: "0rem",
                        background: "white",
                      }}
                    ></button>
                  </div>
                </div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-1 border-bottom"></div>
                <div className="flex row justify-center mt-4 pt-1 pb-2">
                  <div className="col text-center">
                    <button
                      type="button"
                      className="btn btn-dark shadow-none"
                      style={{
                        borderColor: "#222222",
                        height: "2.5rem",
                        width: "12.3rem",
                        borderRadius: "3rem",
                        fontSize: "14px",
                        background: "#222222",
                      }}
                      onClick={() =>
                        this.state.tt_signin === false
                          ? this.setState({ tt_signin: true })
                          : this.setState({ tt_signin: false })
                      }
                    >
                      {this.state.tt_signin === false
                        ? "Sign in with email"
                        : "Close"}
                    </button>
                  </div>
                </div>

                {this.state.tt_signin && (
                  <form name="wordForm" onSubmit={this.doSubmit}>
                    <div className="flex justify-center text-center mt-4">
                      <div className="col text-center justify-center">
                        <input
                          type="text"
                          maxLength="30"
                          className="form-control-lg text-center"
                          id="inputUsername"
                          name="username"
                          placeholder="Username"
                          onChange={this.handleChange}
                          value={data["username"]}
                        />
                      </div>
                    </div>
                    <div className="flex justify-center text-center mt-2">
                      <input
                        type="password"
                        maxLength="30"
                        className="form-control-lg text-center"
                        id="inputPassword"
                        name="password"
                        placeholder="Password"
                        onChange={this.handleChange}
                        value={data["password"]}
                      />
                    </div>
                    <div className="row justify-center mt-3">
                      <div className="col text-center">
                        <button
                          type="submit"
                          className="btn btn-outline-dark shadow-none"
                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                    <div className="row justify-center mt-3">
                      <div className="col text-center">
                        <Link to="/forgotpassword">
                          <button
                            type="button"
                            className="btn btn-light btn-sm shadow-none active"
                            style={{
                              borderStyle: "none",
                              backgroundColor: "transparent",
                              textDecoration: "underline",
                            }}
                          >
                            Forgot Password
                          </button>
                        </Link>
                      </div>
                    </div>
                  </form>
                )}
                {this.state.errors && (
                  <div className="row justify-center mt-3">
                    <div className="col text-center">
                      <h6 className="text-center fs-6 ">
                        Your username or password was incorrect
                      </h6>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LoginForm;
