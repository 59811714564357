import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";
import { faRobot } from "@fortawesome/free-solid-svg-icons";
import { faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import RadioButton from "./radioButton";
import RadioButtonWord from "./radioButtonWord";
import React from "react";
import IdeaForm from "./ideaForm";
import { create_idea } from "../services/generateService";
import SaveIdea from "./saveIdea";

class Generator extends IdeaForm {
  state = {
    data: { word1: "", word2: "" },
    errors: { word1: "", word2: "" },
    wordNumber: "1",
    semantic: "serious",
    isLoadingIdea: false,
    result: "",
    ideaId: -1,
    theme: "white",
  };

  onChangeSemantic = () => {
    let { semantic } = { ...this.state };
    if (semantic === "serious") {
      semantic = "funny";
      this.setState({ semantic });
    } else {
      this.setState({ semantic: "serious" });
    }
  };

  onChangeWordNum = () => {
    let { wordNumber, data } = { ...this.state };
    if (wordNumber === "1") {
      wordNumber = "2";
      this.setState({ wordNumber });
    } else {
      const { errors } = { ...this.state };
      errors["word2"] = "";
      this.setState({ errors });
      data["word2"] = "";
      this.setState({ wordNumber: "1" });
    }
  };
  doSubmit = async () => {
    const { data, wordNumber, semantic } = { ...this.state };

    let canCall = false;
    if (wordNumber === "1") {
      this.validateInput("word1");
      const { word1 } = { ...this.state.errors };
      if (word1 === "") {
        canCall = true;
      }
    } else {
      this.validateInput("word1");
      this.validateInput("word2");
      const { word1, word2 } = { ...this.state.errors };
      if (word1 === "" && word2 === "") {
        canCall = true;
      }
    }
    this.setState({ result: "" });
    // get errors for words
    if (canCall) {
      const token = localStorage.getItem("token");
      const ideaData = {
        wordCount: wordNumber,
        word1: data["word1"],
        word2: data["word2"],
        language: "en",
        semantic: semantic,
      };
      try {
        this.setState({ isLoadingIdea: true });
        const response = await create_idea(ideaData, token);
        this.setState({ isLoadingIdea: false });
        if (response.status === 201) {
          let result = response.data.output;
          const ideaId = response.data.id;
          this.setState({ ideaId });
          this.setState({ result });
        }
      } catch (error) {
        this.setState({ isLoadingIdea: false });
        if (error.response.status === 401) {
          let result = error.response.data;
          this.setState({ result });
          this.setState({ ideaId: -1 });
        }
      }
    }
  };

  render() {
    const { wordNumber, errors, result, isLoadingIdea, ideaId, theme } = {
      ...this.state,
    };
    const { user } = this.props;

    return (
      <div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            {!isLoadingIdea && (
              <div className="col text-end ">
                <FontAwesomeIcon icon={faRobot} size="5x" color="gray" />
              </div>
            )}
            {isLoadingIdea && (
              <div className="col text-end ">
                <FontAwesomeIcon icon={faRobot} size="5x" color="gray" fade />
              </div>
            )}

            {!isLoadingIdea && (
              <div className="col text-center  ">
                <FontAwesomeIcon
                  className="brain"
                  icon={faBrain}
                  size="6x"
                  onClick={this.handleSubmit}
                  cursor="pointer"
                  style={{ animationDuration: "2.3s" }}
                  beatFade
                />
              </div>
            )}
            {isLoadingIdea && (
              <div className="col text-center  ">
                <FontAwesomeIcon
                  className="brain"
                  icon={faBrain}
                  size="6x"
                  onClick={this.handleSubmit}
                  cursor="pointer"
                  style={{ animationDuration: "0.5s" }}
                  beatFade
                />
              </div>
            )}
            {!isLoadingIdea && (
              <div className="col text-left">
                <FontAwesomeIcon icon={faCodeBranch} size="5x" color="gray" />
              </div>
            )}
            {isLoadingIdea && (
              <div className="col text-left">
                <FontAwesomeIcon
                  icon={faCodeBranch}
                  size="5x"
                  color="gray"
                  fade
                />
              </div>
            )}
          </div>
          {user && (
            <div className="row mt-5 justify-content-center">
              <div className="col">
                <p className=" text-center fs-1 fw-bold font-monospace">
                  Hello, {user.username}
                </p>
              </div>
            </div>
          )}
          <div className="row mt-3 justify-content-center">
            <div className="col">
              <p className=" text-center fs-1 fw-bold font-monospace">
                Generate ideas using AI.
              </p>
            </div>
          </div>
          <div className="row mt-5 mx-1 justify-content-center">
            <div className="col-md-8 col-sm-12 text-center mt-3">
              <RadioButton handleChange={this.onChangeSemantic} />
            </div>
            <div className="col-md-8 col-sm-12 text-center mt-3">
              <RadioButtonWord handleChange={this.onChangeWordNum} />
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col-md-8 col-sm-12 text-center mt-3">
              <form name="wordForm" onSubmit={this.handleSubmit}>
                <div className="row justify-content-center">
                  <div className="col ">{this.renderInput("word1")}</div>
                  {wordNumber === "2" && (
                    <div className="col">{this.renderInput("word2")}</div>
                  )}
                </div>
                <div className="row mt-4 mb-3 justify-content-center">
                  <div className="col text-center">
                    <button
                      type="submit"
                      className="btn btn-outline-dark shadow-none"
                      disabled={isLoadingIdea}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {(errors.word1 !== "" || errors.word2 !== "") && (
            <div className="row mt-5 justify-content-center">
              <div className="col-md-8 col-sm-12 text-center">
                <div className="card-light">
                  <div className="card-body-light">
                    {Object.keys(errors).map((k) => (
                      <div key={k}>
                        <p className="fs-6 font-monospace">{errors[k]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {result && !isLoadingIdea && (
            <div className="row mt-5 mb-4 justify-content-center">
              <div className="col-md-8 col-sm-12 text-center">
                <div className="card mb-3">
                  <div className="card-body">
                    <p className="text-center mt-3 mb-3 fs-5 fw-bold font-monospace">
                      {result}
                    </p>
                    {ideaId !== -1 && <SaveIdea ideaId={ideaId} />}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Generator;
