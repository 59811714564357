import React, { Component } from "react";
import { createCheckoutSession } from "../services/authService";

class ProductDisplay extends Component {
  state = { quantity: 1, totalPrice: 0.99, numToken: 30 };

  handleClick = async () => {
    const token = localStorage.getItem("token");
    const data = { purchase_type: "one_time", num_bundle: this.state.quantity };
    const response = await createCheckoutSession(data, token);
    if (response.status === 200) {
      window.location.href = response.data.url;
    }
  };
  calculateTotalPrice = (qty) => {
    const pricePerItem = 0.99; // You can replace this with a variable that holds the actual price per item
    if (qty > 0 && qty < 34) {
      let newPrice = qty * pricePerItem
      let newPriceRounded = newPrice.toFixed(2)
      this.setState({ totalPrice: newPriceRounded });
      this.setState({ numToken: qty * 30 });
    } else {
      this.setState({ totalPrice: 0.99, quantity: 1, numToken: 30 });
    }
  };

  handleQuantityChange = (event) => {
    const newQuantity = event.target.value;
    
    this.setState({ quantity: newQuantity });
    this.calculateTotalPrice(newQuantity);
  };

  render() {
    const { quantity, totalPrice, numToken } = { ...this.state };
    return (
      <div className="row mt-3 justify-content-center">
        <div className="col text-center">
          <form className="form-inline">
            <div className="form-group">
              <label htmlFor="quantity" style={{ marginRight: "1rem" }}>
                <p className="font-monospace"> Number of bundles: </p>
              </label>
              <input
                type="number"
                className="form-control-xs text-center"
                id="quantity"
                name="quantity"
                style={{ width: "3rem" }}
                value={quantity}
                onChange={this.handleQuantityChange}
              />
            </div>
            <p>
              Total tokens: {numToken} - Price: ${totalPrice}
            </p>
          </form>
          <button
              type="button"
              className="btn rounded-pill btn-outline-dark shadow-none"
              onClick={() => this.handleClick()}
            >
              Checkout
            </button>
        </div>
      </div>
    );
  }
}
export default ProductDisplay;
