import React, { Component } from "react";
import Landing from "./landing";
import LandingVerifyEmail from "./landingVerifyEmail";
import LandingLoading from "./landingLoading";
import Generator from "./generator";

class MainPage extends Component {
  state = {};
  render() {
    const { user, isLoaded } = this.props;

    return (
      <div>
        {!isLoaded && <LandingLoading />}

        {isLoaded && !user && <Landing />}

        {user && !user.is_active && <LandingVerifyEmail />}
        
        {user && user.is_active && <Generator user={user} />}
      </div>
    );
  }
}
export default MainPage;
