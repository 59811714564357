import React from "react";

const RadioButtonWord = ({handleChange}) => {
  return (
    <div
      className="btn-group col-4"
      role="group"
      aria-label="Word toggle button group"
    >
      <input
        type="radio"
        className="btn-check"
        name="word-outlined"
        id="oneword-outlined"
        autoComplete="off"
        defaultChecked
        onChange={handleChange}
      />
      <label
        className="btn btn-outline-dark shadow-none btn-lg"
        htmlFor="oneword-outlined"
        style={{ paddingLeft: "1.2rem", paddingRight: "1.2rem" }}
      >
        1
      </label>

      <input
        type="radio"
        className="btn-check"
        name="word-outlined"
        id="twoword-outlined"
        autoComplete="off"
        onChange={handleChange}
      />
      <label
        className="btn btn-outline-dark shadow-none btn-lg"
        htmlFor="twoword-outlined"
      >
        2
      </label>
    </div>
  );
};

export default RadioButtonWord;
