import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

export default function Checkout() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setMessage(
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col text-center">
              <FontAwesomeIcon
                icon={faBrain}
                size="6x"
                style={{ marginTop: "3.1rem", animationDuration: "2s" }}
              />
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col">
              <p className=" text-center fs-2 fw-bold font-monospace">
                Order placed!
              </p>
              <p className=" text-center fs-3 fw-bold font-monospace mt-5">
                You will receive an email confirmation.
              </p>
            </div>
          </div>
        </div>
      );
    }

    if (query.get("canceled")) {
      setMessage(
        <div className="container">
          <div className="row mb-5 justify-content-center">
            <div className="col text-center">
              <FontAwesomeIcon
                icon={faBrain}
                size="6x"
                style={{ marginTop: "3.1rem", animationDuration: "2s" }}
              />
            </div>
          </div>
          <div className="row mt-5 justify-content-center">
            <div className="col">
              <p className=" text-center fs-2 fw-bold font-monospace">
                {" "}
                Order Canceled!
              </p>
              <p className=" text-center fs-3 fw-bold font-monospace mt-5">
                {" "}
                You can always get more tokens by clicking the "?" at the top
                right hand corner.
              </p>
            </div>
          </div>
        </div>
      );
    }
  }, []);

  return message ? (
    <Message message={message} />
  ) : (
    <p className="text-center fs-3 font-monospace mt-5">
      You can get more tokens by clicking the "?" at the top right hand corner.
    </p>
  );
}
