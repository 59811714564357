import React, { Component } from "react";
import { logout } from "../services/authService";

class Logout extends Component {
  state = {};

  handleClick = async (token) => {
    const response = await logout(token);
    localStorage.removeItem("token");
    window.location = "/";
  };
  render() {
    const token = localStorage.getItem("token");

    return (
      <button
        type="button"
        className="btn rounded-pill btn-outline-dark shadow-none"
        onClick={() => this.handleClick(token)}
      >
        Sign Out
      </button>
    );
  }
}

export default Logout;
