import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBrain } from "@fortawesome/free-solid-svg-icons";

class LandingVerifyEmail extends Component {
  state = {};
  render() {
    return (
      <div>
        <div className="col text-center">
          <FontAwesomeIcon
            icon={faBrain}
            size="6x"
            style={{ marginTop: "3.1rem", animationDuration: "2s" }}
          />
        </div>
          <p
            className="text-center fs-1 fw-bold font-monospace"
            style={{ marginTop: "3rem" }}
          >
            Please verify your email to continue
          </p>
      </div>
    );
  }
}
export default LandingVerifyEmail;
